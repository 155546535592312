import React from "react";
import { ContentLayout, HeaderBreadcrumbs } from "../../../shared";
import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useXeroAndMyOb } from "../hooks/useXeroAndMyOb";
import InputControl from "../../../shared/components/InputControl";
import ReportGenerationPanel from "../components/ReportGenerationPanel";
import CollapsibleSettingsTable from "../components/CollapsibleSettingsTable";
import HistoryList from "../components/HistoryList";

export const XeroAndMyObList = () => {
  const {
    breadcrumbsLink,
    storeList,
    isStoreAdmin,
    state,
    title,
    dateValidator,
    settingsValidator,
    globalData,
    handleRowChange,
    handleSaveUpdates,
    handleSearch,
    onGenerateReport,
    validateDate,
    handleSearchClear,
    handleInputChange,
    filterAccountSettingsList,
    setState,
    handleConnectAndDisconnect,
    gotoPage,
    setPageSize,
    handleSort,
    onChangeFilter,
    handleChangeStatus,
    handleIsOpen,
    handleDateChangeApply,
    onChangeDate,
    resetFilters,
    handleSync,
    handleExportModal,
    handleMoreModal,
  } = useXeroAndMyOb();
  const { connectionDetails } = state
  // Tab configuration
  const tabs = React.useMemo(() => {
    // Filter the list on search
    const filteredArray =
      state.searchValue === ""
        ? state.accountSettingsList
        : filterAccountSettingsList({ value: state.searchValue });

    // Trigger error message
    settingsValidator.current.showMessages();



    return [
      {
        label: "Settings",
        value: "1",
        hidden: title === "xero" ? true : false,
        panel: (
          <ReportGenerationPanel
            {...{
              title,
              handleInputChange,
              onSubmit: onGenerateReport,
              state,
              validateDate,
              validator: dateValidator,
              handleConnectAndDisconnect: handleConnectAndDisconnect,
              handleSync,
            }}
          />
        ),
      },
      {
        label: "Accounts Mapping",
        value: "2",
        panel: (
          <CollapsibleSettingsTable
            {...{
              title,
              state,
              tableData: filteredArray,
              isLoading: state.isBusy,
              handleSearch,
              handleSearchClear,
              handleRowChange,
              handleSaveUpdates,
              validator: settingsValidator,
            }}
          />
        ),
      },
      {
        label: "Sync",
        value: "3",
        hidden: title === "xero" ? false : true,
        panel: (
          <HistoryList
            {...{
              title,
              state,
              globalData,
              gotoPage,
              setPageSize,
              handleSort,
              onChangeFilter,
              handleChangeStatus,
              handleIsOpen,
              handleDateChangeApply,
              onChangeDate,
              resetFilters,
              handleInputChange,
              onSubmit: onGenerateReport,
              validateDate,
              validator: dateValidator,
              handleConnectAndDisconnect: handleConnectAndDisconnect,
              handleSync,
              handleExportModal,
              handleMoreModal,
            }}
          />
        ),
      },
    ];
  }, [
    state.isBusy,
    state.data,
    state.validation,
    state.searchValue,
    settingsValidator,
    state.accountSettingsList,
    state.isSettingsUpdated,
    state.isSaving,
    title,
    state.isLoading,
    state.isJournalOpen,
    state.isSyncOpen,
    state.isHistoryBusy,
    state.filterApplied,
    state.syncDates,
    state.syncLoading,
    state.journalDate,
    state.syncDate,
    state.exportModal,
    state.data.store_id,
    state.moreModal,
    state.moreTexts,
  ]);

  return (
    <ContentLayout
      breadcrumbs={<HeaderBreadcrumbs heading="" links={breadcrumbsLink} />}
      title={title.toUpperCase()}
      tabProps={{
        label: title,
        list: tabs,
      }}
      rightContent={
        (!isStoreAdmin && title === "myob") && (
          <Box component="div" sx={{ minWidth: "271px" }}>
            <InputControl
              type="dropdown"
              name="store_id"
              disableClearable
              options={storeList ?? []}
              id="disable-close-on-select"
              getOptionLabel={(option) => option.name}
              value={
                (storeList?.length > 0 &&
                  state.data.store_id &&
                  storeList?.find((c) => c.id === state.data.store_id)) ||
                null
              }
              onChange={(e, newValue) => {
                handleInputChange(
                  {
                    target: {
                      name: "store_id",
                      value: newValue.id,
                    },
                  },
                  "store_id",
                  newValue.name
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store"
                  error={dateValidator.current.message(
                    "store",
                    state.data.store_id,
                    "required"
                  )}
                  helperText={dateValidator.current.message(
                    "store",
                    state.data.store_id,
                    "required"
                  )}
                />
              )}
            />
          </Box>
        )
      }
      topContent={title == "xero" && (<>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "8px"
            }}
          >
            <img alt="store" src={connectionDetails?.connection_status ? "/icons/ic_store_connected.svg" : "/icons/ic_store_not_connected.svg"} />
            <Stack>
              {
                isStoreAdmin ?
                  <Typography variant="subtitle2" fontWeight={600} paddingLeft="8px" mb="4px">{connectionDetails?.store_name}</Typography>
                  : (
                    <Box component="div" sx={{ minWidth: "271px" }}>
                      <InputControl
                        type="dropdown"
                        name="store_id"
                        disableClearable
                        options={storeList ?? []}
                        id="disable-close-on-select"
                        getOptionLabel={(option) => option.name}
                        value={
                          (storeList?.length > 0 &&
                            state.data.store_id &&
                            storeList?.find((c) => c.id === state.data.store_id)) ||
                          null
                        }
                        onChange={(e, newValue) => {
                          handleInputChange(
                            {
                              target: {
                                name: "store_id",
                                value: newValue.id,
                              },
                            },
                            "store_id",
                            newValue.name
                          );
                        }}
                        sx={{
                          "& input": { px: "0px !important" },
                          "& fieldset": { border: "none" },
                          "& .MuiOutlinedInput-root": {
                            padding: "6px 8px !important",
                            height: "32px !important",
                          },
                        }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "fit-content",
                              minWidth: 250,
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{
                              width: `${Math.max(150, params.inputProps.value.length * 12)}px`, // Adjust width based on input length
                              transition: 'width 0.3s ease-in-out',
                            }}
                            error={dateValidator.current.message(
                              "store",
                              state.data.store_id,
                              "required"
                            )}
                            helperText={dateValidator.current.message(
                              "store",
                              state.data.store_id,
                              "required"
                            )}
                          />
                        )
                        }
                      />
                    </Box>
                  )
              }
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "14px",
                  pl: "8px",
                  color: "#636363"
                }}>
                {!connectionDetails?.connection_status ? "Not Connected" : connectionDetails?.organisation ? `Connected with ${connectionDetails?.organisation}` : ""}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "22px"
            }}
          >
            {connectionDetails.last_sync_details ? (<Typography color="#636363" variant="subtitle2" sx={{ display: "flex", gap: "6px" }}>
              <img alt="refres" src="/icons/ic_refresh.svg" />
              Last Synced: {connectionDetails.last_sync_details?.sync_date}  &bull; <span style={{ color: connectionDetails.last_sync_details?.status == "Failure" ? "#C42B1C" : connectionDetails.last_sync_details?.status == "Success" ? "#0F875B" : "#C07302" }}>{connectionDetails.last_sync_details?.status}</span>
            </Typography>
            ) : ""}
            <Button
              variant="contained"
              sx={{
                backgroundColor: connectionDetails?.connection_status ? "#E94C32" : "#1560D4",
                border: "none"
              }}
              onClick={handleConnectAndDisconnect}
              disabled={state.isLoading}
            >
              {connectionDetails?.connection_status ? "Disconnect " : "Connect "}{title.toUpperCase()}
            </Button>
          </Stack>
        </Stack>
      </>)}
    ></ContentLayout >
  );
};
