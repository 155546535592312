import { useContext } from "react";
import { AppContext } from "../../store/AppScopeProvider";

const defaultCurrentTabs = {
  profile: "1",
  stateandSuburb: "1",
  rolesAndPermission: "1",
  rolesAndPermissionList: "1",
  customers: "1",
  taxGroupsAndTaxes: "1",
  adminUsers: "1",
  productDetails: "1",
  availabilityGroupDetails: "1",
  storeDetails: "1",
  favourites: "1",
  CouponseDetails: "1",
  birthdayCoupons: "1",
  loyaltyList: "1",
  giftCardGroupDetails: "1",
  loyaltyGroupDetails: "1",
  giftCardList: "1",
  discountGroupDetails: "1",
  categoryDetails: "1",
  clientAccDetails: "1",
  loyaltyCardTypeDetails: "1",
  menulog: "1",
  ubereats: "1",
  doordash: "1",
  onlineOrdering: "1",
  dashboard: "1",
  xero: "2",
  myob: "1",
  generalTab: "1",
  order: "1",
};

export const useTabs = () => {
  const { setAppState } = useContext(AppContext);

  const setCurentTab = (name, value) => {
    setAppState((draft) => {
      draft.currentTabs[name] = value;
    });
  };

  const resetCurrentTabs = () => {
    setAppState((draft) => {
      draft.currentTabs = defaultCurrentTabs;
    });
  };

  return { setCurentTab, resetCurrentTabs };
};
