import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ContentLayout,
  CustomDetailsList,
  EndofDayTable,
  EndofDayTableSimple,
  HeaderBreadcrumbs,
  Loader,
} from "../../../shared";
import { useEndofDay } from "../hooks";
import SimpleReactValidator from "simple-react-validator";

export const EndofDayDetails = () => {
  const { id } = useParams();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const navigate = useNavigate();
  const {
    state,
    isSummaryVisble,
    isEditable,
    Cashtotal,
    setState,
    onUpdateEndofDay,
  } = useEndofDay({
    isEndofDay: false,
    EndofdayId: id,
    isStore: false,
    isDetail: true,
  });
  console.log("data", state.data);
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const columns = [
    {
      title: "Staff Name",
      accessor: "user_name",
    },
    {
      title: "Terminal Name",
      accessor: "terminal_name",
    },
    {
      title: "Store",
      accessor: "store_name",
    },
    {
      title: "Sale Date",
      accessor: "sale_date",
    },
    {
      title: "Updated Date",
      accessor: "formatted_updated_at",
    },
    {
      title: "Status",
      accessor: (val) => {
        return (
          <Stack direction="row" spacing={2}>
            <Chip
              label={val.status === 0 ? "Pending" : "Approved"}
              color={val.status === 1 ? "success" : "warning"}
              variant={"outlined"}
              sx={{ bgcolor: "#FFF4CE", mt: 1 }}
              size="small"
            />
            {state.data.status === 0 && isSummaryVisble && (
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={() => onUpdateEndofDay(id)}
              >
                End the Day
              </Button>
            )}
          </Stack>
        );
      },
    },
  ];
  const column = [
    {
      title: "Float Amount",
      accessor: "float_total",
      accessor: (row) => {
        return <div>{"$" + row.float_total?.toFixed(2)}</div>;
      },
      hidden: (row) => {
        return row.cashless_till ? true : false;
      },
    },
    {
      title: "Cheque",
      accessor: "cheque_total",
      accessor: (row) => {
        return <div>{"$" + row.cheque_total}</div>;
      },
    },
    {
      title: "Remarks",
      backgroundColor: "#FFF4CE",
      hidden: (row) => {
        return row.remarks === null;
      },
      accessor: (row) => {
        return (
          <Typography color="#C07302" fontWeight={400} fontSize={16}>
            {row.remarks}
          </Typography>
        );
      },
    },
  ];
  const variance =
    Number(Cashtotal) -
    Number(
      state?.endofdaySummary?.system_cash
        ? state?.endofdaySummary?.system_cash
        : 0
    );
  const isTerminal = state.data.terminal_id !== null;

  return (
    <>
      {state.isBusy ? (
        <Loader />
      ) : (
        <ContentLayout
          title={state.data.store_name + "-" + state.data.terminal_name}
          breadcrumbs={
            <>
              <HeaderBreadcrumbs
                links={[
                  {
                    name: "Dashboard",
                    href: isStoreAdmin ? "/store-admin" : "/",
                  },
                  {
                    name: "End of Day",
                    href: isStoreAdmin
                      ? "/store-admin/end-of-day"
                      : "/end-of-day",
                  },
                  { name: "details" },
                ]}
              />
            </>
          }
        >
          <CustomDetailsList
            columns={columns}
            data={state?.data}
            title="Basic Details"
          />
          {isTerminal && (
            <Card>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                height="83px"
                p="32px 24px"
              >
                <Typography variant="subtitle1" fontWeight={600}>
                  End of Day
                </Typography>
                {isSummaryVisble ? (
                  <>
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() =>
                        isStoreAdmin
                          ? navigate(`/store-admin/end-of-day/edit/${id}`)
                          : navigate(`/end-of-day/edit/${id}`)
                      }
                    >
                      Edit
                    </Button>
                  </>
                ) : (
                  <>
                    {isEditable && state.data.status === 0 ? (
                      <Button
                        size="medium"
                        variant="contained"
                        onClick={() =>
                          isStoreAdmin
                            ? navigate(`/store-admin/end-of-day/edit/${id}`)
                            : navigate(`/end-of-day/edit/${id}`)
                        }
                      >
                        Edit
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Stack>
              <Divider />
              <CustomDetailsList
                columns={column}
                data={state.data}
                marginBottom={0}
              />
              {!state.data.cashless_till && (
                <EndofDayTableSimple
                  denominations={state.data.denominations}
                  float_amount={state.data.float_total}
                  setState={setState}
                  stateKey="data"
                  disableTable={true}
                  cash_banked_total={state.data?.cash_banked_total || 0}
                  isDetails={true}
                />
              )}

              <Divider />
              {!isSummaryVisble && (
                <Grid
                  container
                  sx={{
                    p: "29px 24px",
                    backgroundColor: "#E1FBF2",
                  }}
                >
                  <Grid item md={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="#0F875B"
                    >
                      Total Amount
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography fontWeight={600} fontSize={24} color="#0F875B">
                      ${(Cashtotal + +state.data.cheque_total).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Card>
          )}
          <Box marginTop={2} marginBottom={5}>
            {isSummaryVisble && (
              <Card>
                <Grid
                  container
                  sx={{
                    height: "83px",
                    padding: "32px 24px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      fontSize={16}
                      sx={{
                        lineHeight: "19px",
                      }}
                    >
                      Summary
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Cash in Hand</Typography>
                    <Typography>
                      $
                      {Cashtotal
                        ? Cashtotal.toFixed(2)
                        : state.endofdaySummary.cash_total}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Loyalty</Typography>

                    <Typography>
                      <Typography>
                        $
                        {state?.endofdaySummary?.loyalty_total
                          ? state?.endofdaySummary?.loyalty_total?.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>System Cash</Typography>
                    <Typography>
                      $
                      {state.endofdaySummary.system_cash
                        ? state.endofdaySummary.system_cash.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Gift Card</Typography>
                    <Typography>
                      $
                      {state?.endofdaySummary?.gift_card_total
                        ? state?.endofdaySummary?.gift_card_total.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Round off</Typography>
                    <Typography>
                      <Typography>
                        $
                        {state?.endofdaySummary?.round_off_total
                          ? state?.endofdaySummary?.round_off_total.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Client Account</Typography>
                    <Typography>
                      $
                      {state?.endofdaySummary?.client_account_total
                        ? state?.endofdaySummary?.client_account_total.toFixed(
                          2
                        )
                        : "0.00"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Variance</Typography>
                    <Typography fontWeight={500} color={variance === 0 ? "#0F875B" : "#C42B1C"}>
                      ${variance.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>EFT/Card</Typography>
                    <Typography>
                      <Typography>
                        $
                        {state.endofdaySummary.card_total
                          ? state.endofdaySummary.card_total.toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Cheque</Typography>
                    <Typography>
                      $
                      {state.endofdaySummary.cheque_total
                        ? state?.endofdaySummary?.cheque_total.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography >Stripe</Typography>
                    <Typography >
                      $
                      {state?.endofdaySummary?.online_ordering_sales
                        ? state.endofdaySummary?.online_ordering_sales.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Menulog</Typography>
                    <Typography>
                      $
                      {state.endofdaySummary?.menulog_sales
                        ? state?.endofdaySummary?.menulog_sales.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography >DoorDash</Typography>
                    <Typography >
                      $
                      {state?.endofdaySummary?.dd_sales
                        ? state.endofdaySummary?.dd_sales.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography>Uber Eats</Typography>
                    <Typography>
                      $
                      {state.endofdaySummary?.ubereats_sales
                        ? state?.endofdaySummary?.ubereats_sales.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                  <Stack
                    border={1}
                    borderColor="#E6E6E6"
                    width="100%"
                    height="48px"
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "14.5px 26px",
                    }}
                  >
                    <Typography color="#808080">Total Tax</Typography>
                    <Typography color="#808080">
                      $
                      {state?.endofdaySummary?.total_tax
                        ? state.endofdaySummary?.total_tax.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Stack>
                </Stack>
                {/* <Box
                  component="main"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#E1FBF2",
                    p: "32px 24px",
                  }}
                >
                  <Typography fontWeight={600} fontSize={24} color="#0F875B">
                    Total Amount: $
                    {(Cashtotal + +state.data.cheque_total).toFixed(2)}
                  </Typography>
                </Box> */}
              </Card>
            )}
          </Box>
        </ContentLayout>
      )}
    </>
  );
};
